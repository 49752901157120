import {useTranslation} from 'react-i18next';
import {Button} from 'src/components/form/button/Button';

import './InfoTextsDialog.scss';
import {useCookies} from 'react-cookie';
import {InfoTextsActions, useInfoTextsContext} from 'src/context/InfoTextsContext';

export function InfoTextsDialog() {
    const {t} = useTranslation();

    const {infoTextsState, dispatch} = useInfoTextsContext();
    const {infoTexts, uniqueTypes, infoTextsDisplayDialog} = infoTextsState;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies();

    const defaultCookieOptions = {
        domain: '.mittvaccin.se',
        path: '/',
        secure: true
    };

    return (
        <>
            {infoTextsDisplayDialog && (
                <div className='info_texts_dialog_wrapper'>
                    {infoTexts?.map((request, index) => {
                        return (
                            <div key={`info_texts-` + index} className='info_texts_dialog_item'>
                                <p className='info-texts-title'>{request.title}</p>
                                <p className='info-texts-message'>{request.html}</p>
                            </div>
                        );
                    })}

                    <Button
                        big
                        className='info-text-error-button'
                        onClick={() => {
                            dispatch({type: InfoTextsActions.SET_DISPLAY_DIALOG, value: !infoTextsDisplayDialog});

                            if (infoTextsDisplayDialog && uniqueTypes.includes('ERROR')) {
                                setCookie('mv-infoTexts-dialog-open', infoTexts[0].updated, defaultCookieOptions);
                            }
                        }}
                    >
                        <span>{t('error_ok_button.text')}</span>
                    </Button>
                </div>
            )}
        </>
    );
}
