type IconProps = {
    width?: string;
    height?: string;
};

export function ErrorIcon(props: IconProps) {
    return (
        <>
            <svg
                width={props?.width || '24'}
                height={props?.height || '24'}
                viewBox='0 0 24 24'
                fill='#E30031'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    id='Vector'
                    d='M10.9853 2.47989L0.341467 20.9358C0.230014 21.1291 0.171356 21.3485 0.171387 21.5718C0.171418 21.795 0.230137 22.0144 0.341644 22.2077C0.453151 22.4011 0.613519 22.5616 0.806633 22.6733C0.999747 22.7849 1.21881 22.8437 1.4418 22.8437H22.7285C22.9515 22.8437 23.1706 22.7849 23.3637 22.6733C23.5568 22.5616 23.7172 22.4011 23.8287 22.2077C23.9402 22.0144 23.9989 21.795 23.9989 21.5718C23.999 21.3485 23.9403 21.1291 23.8289 20.9358L13.1855 2.47989C13.0744 2.286 12.9142 2.12489 12.7211 2.01284C12.5279 1.9008 12.3086 1.8418 12.0854 1.8418C11.8622 1.8418 11.6429 1.9008 11.4497 2.01284C11.2566 2.12489 11.0964 2.286 10.9853 2.47989Z'
                    fill='white'
                />
                <path
                    id='Vector_2'
                    d='M13.519 7.24219L12.5567 16.3107H11.5661L10.6516 7.24219H13.519ZM13.2239 17.8768V20.2614H10.8516V17.8768H13.2239Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
