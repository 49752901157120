import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HandleBookingActions, useHandleBookingContext} from '../../../context/HandleBookingContext';
import {fetchUnreserveTimeSlot} from '../../../services/api/api';
import {BookingResponse} from '../../../services/api/types/types';
import {Button} from '../../form/button/Button';
import {ChangeTimeSlot} from './ChangeTimeSlot';
import {Booking} from './components/Booking';
import {ConfirmUnbookingModal} from './components/ConfirmUnbookingModal';
import {EditContactInfoModal} from './components/EditContactInfoModal';
import {debug} from '../../../services/debug/debug';
import {UnbookCompleted} from './components/UnbookCompleted';
import {useStateRestore} from 'src/hooks/useStateRestore';

interface HandleOpenBookingProps {
    booking: BookingResponse;
}

export function HandleOpenBooking({booking}: HandleOpenBookingProps) {
    const {t} = useTranslation();
    const {dispatch, state, refreshBooking} = useHandleBookingContext();
    const {removeHandleBookingStateCache} = useStateRestore();
    const {editTimeSlot, editContactInfo, confirmUnbooking} = state;

    const [sessionTimedOut, setSessionTimedOut] = useState(false);
    const [unbookCompleted, setUnbookCompleted] = useState(false);

    const sessionTimer = useRef<NodeJS.Timeout>();
    const sessionWarningTimer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (unbookCompleted) {
            clearTimeout(sessionWarningTimer.current);
            clearTimeout(sessionTimer.current);
            return;
        }

        // 20 minutes "session" timeout (2 minutes during debug)
        const timeoutMs = debug.debugActive ? 120 * 1000 : 20 * 60 * 1000;

        sessionTimer.current = setTimeout(() => {
            removeHandleBookingStateCache();
            setSessionTimedOut(true);
        }, timeoutMs);

        return () => {
            clearTimeout(sessionWarningTimer.current);
            clearTimeout(sessionTimer.current);
        };
    }, [unbookCompleted, removeHandleBookingStateCache]);

    if (unbookCompleted) {
        removeHandleBookingStateCache();
        return <UnbookCompleted booking={booking} />;
    }

    return (
        <>
            {sessionTimedOut && (
                <section aria-live='assertive' aria-atomic>
                    <h1 className='error-container__header'>{t('session.handle_booking_expired_heading')}</h1>
                    <p className='error-container__info-text'>{t('session.handle_booking_expired_content')}</p>
                    <Button
                        className='error-container__button'
                        big
                        onClick={() => {
                            dispatch({type: HandleBookingActions.CLEAR_CONTEXT, value: null});
                            window.location.reload();
                        }}
                    >
                        <span>{t('session.handle_booking_expired_button')}</span>
                    </Button>
                </section>
            )}

            {!sessionTimedOut && (
                <>
                    {editTimeSlot === null && (
                        <>
                            <h1 className='handle-booking__heading'>{t('handle_booking.your_booking')}</h1>
                            <h2 className='handle-booking__extra-heading'>
                                {t('handle_booking.booking_code_x', {bookingCode: booking.bookingCode})}
                            </h2>

                            <Booking
                                booking={booking}
                                openBooking
                                onEditTimeSlotClick={(booking) =>
                                    dispatch({type: HandleBookingActions.EDIT_TIMESLOT, value: booking})
                                }
                                onEditContactInfoClick={() =>
                                    dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: booking})
                                }
                                onCancelBookingClick={() =>
                                    dispatch({type: HandleBookingActions.CONFIRM_UNBOOKING, value: booking})
                                }
                            />
                        </>
                    )}

                    {confirmUnbooking && (
                        <ConfirmUnbookingModal
                            handleCloseModal={() =>
                                dispatch({type: HandleBookingActions.CONFIRM_UNBOOKING, value: null})
                            }
                            onConfirmClick={async () => {
                                await fetchUnreserveTimeSlot(confirmUnbooking.clinicId, confirmUnbooking.id, null);
                                setUnbookCompleted(true);
                            }}
                        />
                    )}
                    {editContactInfo && (
                        <EditContactInfoModal
                            editBooking={editContactInfo}
                            onBookingUpdated={(updatedData) => {
                                dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: null});
                                refreshBooking(booking.bookingCode, booking.patients[0].identity);
                                dispatch({
                                    type: HandleBookingActions.SET_SNACKBAR_MESSAGE,
                                    value: t('handle_booking.contact_updated_snackbar')
                                });
                            }}
                            handleCloseModal={() =>
                                dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: null})
                            }
                            userJwt={null}
                        />
                    )}
                    {editTimeSlot !== null && (
                        <ChangeTimeSlot
                            onBookingUpdated={() => {
                                dispatch({type: HandleBookingActions.EDIT_TIMESLOT, value: null});
                                refreshBooking(booking.bookingCode, booking.patients[0].identity);
                                dispatch({
                                    type: HandleBookingActions.SET_SNACKBAR_MESSAGE,
                                    value: t('handle_booking.timeslot_updated_snackbar')
                                });
                            }}
                            appointmentTypeId={editTimeSlot.appointmentTypeId}
                            initialTimeSlot={new Date(editTimeSlot.when)}
                            bookingId={editTimeSlot.id}
                            clinicId={editTimeSlot.clinicId}
                        />
                    )}
                </>
            )}
        </>
    );
}
