type IconProps = {
    width?: string;
    height?: string;
};

export function WarningIcon(props: IconProps) {
    return (
        <>
            <svg
                width={props?.width || '24'}
                height={props?.height || '24'}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M13.2 7.25C13.2 7.94036 12.6403 8.5 11.95 8.5C11.2596 8.5 10.7 7.94036 10.7 7.25C10.7 6.55964 11.2596 6 11.95 6C12.6403 6 13.2 6.55964 13.2 7.25Z'
                    fill='#E30031'
                />
                <path
                    d='M9.69995 11C9.69995 10.4477 10.1477 10 10.7 10H12.2C12.7522 10 13.2 10.4477 13.2 11V16H13.7C14.2522 16 14.7 16.4477 14.7 17C14.7 17.5523 14.2522 18 13.7 18H10.7C10.1477 18 9.69995 17.5523 9.69995 17C9.69995 16.4477 10.1477 16 10.7 16H11.2V12H10.7C10.1477 12 9.69995 11.5523 9.69995 11Z'
                    fill='#E30031'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
