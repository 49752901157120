type IconProps = {
    width?: string;
    height?: string;
};

export function AlertIcon(props: IconProps) {
    return (
        <>
            <svg
                width={props?.width || '24'}
                height={props?.height || '24'}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    id='Subtract'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7ZM12 15C12.5523 15 13 15.4477 13 16V16.1C13 16.6523 12.5523 17.1 12 17.1C11.4477 17.1 11 16.6523 11 16.1V16C11 15.4477 11.4477 15 12 15Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
