import React, {createContext, useContext, useEffect, useReducer} from 'react';
import {fetchInfoTexts} from 'src/services/api/api';
import {useSessionContext} from './SessionContext';
import {InfoTextsObject, InfoTextsResponse} from 'src/services/api/types/types';
import {useCookies} from 'react-cookie';

export enum InfoTextsActions {
    SET_INFO_TEXTS = 'SET_INFO_TEXTS',
    SET_DISPLAY_DIALOG = 'SET_DISPLAY_DIALOG',
    SET_UNIQUE_TYPES = 'SET_UNIQUE_TYPES'
}

type InfoTextsAction = {type: InfoTextsActions; value: Array<InfoTextsObject>};
type UniqueTypesAction = {type: InfoTextsActions; value: Array<string>};
type BooleanAction = {type: InfoTextsActions; value: boolean};

type Action = BooleanAction | InfoTextsAction | UniqueTypesAction;

type Dispatch = (action: Action) => void;

type State = {
    infoTexts: Array<InfoTextsObject>;
    infoTextsDisplayDialog: boolean;
    uniqueTypes: Array<string>;
};

type InfoTextsProviderProps = {children: React.ReactNode};

const InfoTextsContext = createContext<{infoTextsState: State; dispatch: Dispatch} | undefined>(undefined);

function showInfoTextDialogDefault(infoTexts: InfoTextsResponse, uniqueTypes: Array<string>, cookieSetDate: string) {
    if (!uniqueTypes?.includes('ERROR')) {
        return false;
    }

    if (cookieSetDate && infoTexts && infoTexts[0].updated <= cookieSetDate) {
        return false;
    }

    return true;
}

function infoTextsReducer(infoTextsState: State, action: Action): State {
    switch (action.type) {
        case InfoTextsActions.SET_INFO_TEXTS: {
            return {...infoTextsState, infoTexts: action.value as Array<InfoTextsObject>};
        }
        case InfoTextsActions.SET_DISPLAY_DIALOG: {
            return {...infoTextsState, infoTextsDisplayDialog: (action as BooleanAction).value};
        }
        case InfoTextsActions.SET_UNIQUE_TYPES: {
            return {...infoTextsState, uniqueTypes: action.value as Array<string>};
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function InfoTextsProvider({children}: InfoTextsProviderProps) {
    const [infoTextsState, dispatch] = useReducer(infoTextsReducer, {
        infoTexts: [],
        infoTextsDisplayDialog: false,
        uniqueTypes: []
    });

    const [cookies] = useCookies();
    const {sessionState} = useSessionContext();

    const getInfoTexts = async () => {
        try {
            const infoTexts = await fetchInfoTexts();
            const uniqueTypes = infoTexts
                ?.map((infoText) => infoText.type)
                .filter((value, index, self) => self.indexOf(value) === index);
            const openByDefault = showInfoTextDialogDefault(
                infoTexts,
                uniqueTypes,
                cookies['mv-infoTexts-dialog-open']
            );

            dispatch({
                type: InfoTextsActions.SET_DISPLAY_DIALOG,
                value: openByDefault
            });
            dispatch({
                type: InfoTextsActions.SET_INFO_TEXTS,
                value: infoTexts
            });
            dispatch({
                type: InfoTextsActions.SET_UNIQUE_TYPES,
                value: uniqueTypes
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!sessionState.sessionTimedOut) {
            getInfoTexts();
        }
    }, [sessionState.sessionTimedOut]);

    const value = {infoTextsState, dispatch};
    return <InfoTextsContext.Provider value={value}>{children}</InfoTextsContext.Provider>;
}

function useInfoTextsContext() {
    const context = useContext(InfoTextsContext);
    if (context === undefined) {
        throw new Error('useInfoTextsContext must be used within a InfoTextsProvider');
    }

    return context;
}

export {InfoTextsProvider, useInfoTextsContext};
