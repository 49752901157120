import {createContext, useCallback, useContext, useEffect, useReducer} from 'react';
import {fetchBookings, fetchSingleBooking} from '../services/api/api';
import {BookingResponse, UserJSON, UserMinimalJSON} from '../services/api/types/types';
import {useSessionContext} from './SessionContext';
import {useStateRestore} from 'src/hooks/useStateRestore';

export enum HandleBookingActions {
    SET_BOOKINGS_LOADED = 'SET_BOOKINGS_LOADED',
    SET_BOOKINGS = 'SET_BOOKINGS',
    SET_USER = 'SET_USER',
    SET_OPEN_BOOKING = 'SET_OPEN_BOOKING',
    EDIT_TIMESLOT = 'EDIT_TIMESLOT',
    TIMESLOT_CHANGED = 'TIMESLOT_CHANGED',
    EDIT_CONTACT_INFO = 'EDIT_CONTACT_INFO',
    CONFIRM_UNBOOKING = 'CONFIRM_UNBOOKING',
    SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE',
    RESTORE_HANDLE_BOOKING_STATE = 'RESTORE_HANDLE_BOOKING_STATE',
    CLEAR_CONTEXT = 'CLEAR_CONTEXT'
}

type Action = {
    type: HandleBookingActions;
    value:
        | boolean
        | null
        | string
        | Error
        | BookingResponse
        | Array<BookingResponse>
        | UserMinimalJSON
        | HandleBookingContextState;
};
export type HandleBookingContextDispatch = (action: Action) => void;
export type HandleBookingContextState = {
    bookingsLoaded: boolean;
    user: UserJSON | null;
    editTimeSlot: BookingResponse | null;
    timeSlotChanged: boolean;
    editContactInfo: BookingResponse | null;
    confirmUnbooking: BookingResponse | null;
    openBooking: BookingResponse | null;
    snackBarMessage: string | null;
    bookings: Array<BookingResponse>;
};
type HandleBookingProviderProps = {children: React.ReactNode};

interface ContextProps {
    state: HandleBookingContextState;
    dispatch: HandleBookingContextDispatch;
    refreshBooking(bookingCode: string, identity: string): Promise<void>;
    refreshAllBookings(userJwt: string): Promise<void>;
    updateBookingUser(): void;
    clearBookingContext(): void;
}

const HandleBookingContext = createContext<ContextProps | undefined>(undefined);
function HandleBookingReducer(state: HandleBookingContextState, action: Action): HandleBookingContextState {
    switch (action.type) {
        case HandleBookingActions.RESTORE_HANDLE_BOOKING_STATE: {
            return action.value as HandleBookingContextState;
        }

        case HandleBookingActions.SET_BOOKINGS_LOADED: {
            return {...state, bookingsLoaded: action.value as boolean};
        }
        case HandleBookingActions.SET_BOOKINGS: {
            return {...state, bookings: action.value as Array<BookingResponse>};
        }
        case HandleBookingActions.SET_OPEN_BOOKING: {
            return {...state, openBooking: action.value as BookingResponse};
        }
        case HandleBookingActions.EDIT_TIMESLOT: {
            return {...state, editTimeSlot: action.value as BookingResponse, timeSlotChanged: false};
        }
        case HandleBookingActions.TIMESLOT_CHANGED: {
            return {...state, timeSlotChanged: action.value as boolean};
        }
        case HandleBookingActions.EDIT_CONTACT_INFO: {
            return {...state, editContactInfo: action.value as BookingResponse};
        }
        case HandleBookingActions.CONFIRM_UNBOOKING: {
            return {...state, confirmUnbooking: action.value as BookingResponse | null};
        }
        case HandleBookingActions.SET_SNACKBAR_MESSAGE: {
            return {...state, snackBarMessage: action.value as string | null};
        }
        case HandleBookingActions.SET_USER: {
            return {...state, user: action.value as UserJSON | null};
        }
        case HandleBookingActions.CLEAR_CONTEXT: {
            sessionStorage.removeItem('handleBookingState');
            return {
                bookingsLoaded: false,
                user: null,
                editTimeSlot: null,
                timeSlotChanged: false,
                editContactInfo: null,
                confirmUnbooking: null,
                openBooking: null,
                snackBarMessage: null,
                bookings: []
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function HandleBookingProvider({children}: HandleBookingProviderProps) {
    const [state, dispatch] = useReducer(HandleBookingReducer, {
        bookingsLoaded: false,
        user: null,
        editTimeSlot: null,
        timeSlotChanged: false,
        editContactInfo: null,
        confirmUnbooking: null,
        openBooking: null,
        snackBarMessage: null,
        bookings: []
    });

    const {getHandleBookingStateFromCache, updateHandleBookingStateCache} = useStateRestore();
    const {sessionState} = useSessionContext();

    const refreshBooking = async (bookingCode: string, identity: string) => {
        dispatch({type: HandleBookingActions.SET_BOOKINGS_LOADED, value: false});
        const booking = await fetchSingleBooking(bookingCode, identity);
        dispatch({type: HandleBookingActions.SET_OPEN_BOOKING, value: booking});
        dispatch({type: HandleBookingActions.SET_BOOKINGS_LOADED, value: true});
    };

    const refreshAllBookings = useCallback(async (userJwt: string) => {
        try {
            dispatch({type: HandleBookingActions.SET_BOOKINGS_LOADED, value: false});
            const bookings = await fetchBookings(userJwt);
            dispatch({type: HandleBookingActions.SET_BOOKINGS, value: bookings});
        } catch (error) {
            //TODO handle error
        } finally {
            dispatch({type: HandleBookingActions.SET_BOOKINGS_LOADED, value: true});
        }
    }, []);

    const updateBookingUser = useCallback(() => {
        if (sessionState.user) {
            dispatch({type: HandleBookingActions.SET_USER, value: sessionState.user});
        }
    }, [sessionState.user]);

    const clearBookingContext = () => {
        dispatch({type: HandleBookingActions.CLEAR_CONTEXT, value: null});
    };

    useEffect(() => {
        const cachedHandleBookingState = getHandleBookingStateFromCache();
        cachedHandleBookingState &&
            dispatch({type: HandleBookingActions.RESTORE_HANDLE_BOOKING_STATE, value: cachedHandleBookingState});
    }, [getHandleBookingStateFromCache]);

    useEffect(() => {
        updateHandleBookingStateCache(state);
    }, [state, updateHandleBookingStateCache]);

    const value = {
        state,
        dispatch,
        refreshBooking,
        refreshAllBookings,
        updateBookingUser,
        clearBookingContext
    };
    return <HandleBookingContext.Provider value={value}>{children}</HandleBookingContext.Provider>;
}

function useHandleBookingContext() {
    const context = useContext(HandleBookingContext);
    if (context === undefined) {
        throw new Error('useHandleBookingContext must be used within a HandleBookingProvider');
    }

    return context;
}

export {HandleBookingProvider, useHandleBookingContext};
