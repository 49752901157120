import {eachDayOfInterval, setHours, setMinutes, isAfter, startOfDay, isPast, addDays} from 'date-fns';
import {getWeek} from '../../../lib/dates/week';
import {
    AccountJSON,
    ApiImplementation,
    CaregiverJSON,
    ClinicJSON,
    ClinicJSONSlim,
    ReasonFilterJSON,
    PlacesFilterJSON,
    AppointmentCategory,
    Country,
    HealthDeclaration,
    UserJSON,
    FinalizeBookingResponse,
    AppointmentType,
    BookingResponse,
    ConsentRequestObject,
    ConsentRequestAnswer,
    HistoryResponse,
    VaccineCategoryResponse,
    InfoTextsResponse
} from '../types/types';
import {ForbiddenError, InvalidIdentityError, NotFoundError} from './fetch';

async function delay(waitTime: number = 1000) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(true);
        }, waitTime);
    });
}

function getDebugPerson() {
    const urlParams = new URLSearchParams(window.location.search);
    const person = urlParams.has('person') ? parseInt(urlParams.get('person') as string, 10) || 0 : 0;

    return person;
}

export const mockApi: ApiImplementation = {
    getAccount: async (jwt: string) => {
        const accountResponse = await fetch('/mock/v1/accountData.json');
        const accountData = (await accountResponse.json()) as AccountJSON;
        await delay(100);
        return accountData;
    },

    createOrUpdateAccount: async (jwt: string, data: AccountJSON) => {
        const accountResponse = await fetch('/mock/v1/accountData.json');
        const accountResponseJSON: Response = await accountResponse.json();
        await delay(100);
        return accountResponseJSON;
    },

    deleteAccount: async (jwt: string) => {
        await delay(2000);
        return {status: 200, ok: true, statusText: 'OK'} as Response;
    },

    getVisitReasons: async () => {
        const visitReasonsResponse = await fetch('/mock/v1/visitReasons.json');

        if (!visitReasonsResponse.ok) {
            console.error(await visitReasonsResponse.text());
            throw new Error(`Unable to fetch visitReasons: ${visitReasonsResponse.status}`);
        }

        await delay();

        const visitReasons = (await visitReasonsResponse.json()) as Array<ReasonFilterJSON>;

        return visitReasons;
    },

    getClinics: async () => {
        const clinicsResponse = await fetch('/mock/v1/clinics-slim.json');

        if (!clinicsResponse.ok) {
            console.error(await clinicsResponse.text());
            throw new Error(`Unable to fetch clinics: ${clinicsResponse.status}`);
        }

        const clinics = (await clinicsResponse.json()) as Array<ClinicJSONSlim>;

        await delay();

        return clinics;
    },

    getClinic: async (clinicId: number) => {
        const clinicResponse = await fetch('/mock/v1/clinics-full.json');

        if (!clinicResponse.ok) {
            console.error(await clinicResponse.text());
            throw new Error(`Unable to fetch clinic: ${clinicResponse.status}`);
        }

        const clinics = (await clinicResponse.json()) as Array<ClinicJSON>;

        await delay();

        const clinic = clinics.find(({id}) => id === clinicId);

        if (!clinic) {
            throw new NotFoundError(`Unable to fetch clinic: ${clinicResponse.status}`);
        }

        return clinic;
    },

    getCaregivers: async () => {
        const caregiversResponse = await fetch('/mock/v1/careGivers.json');

        if (!caregiversResponse.ok) {
            console.error(await caregiversResponse.text());
            throw new Error(`Unable to fetch careGivers: ${caregiversResponse.status}`);
        }

        const caregiversData = (await caregiversResponse.json()) as Array<CaregiverJSON>;

        await delay();

        return caregiversData;
    },

    caregiverCookiePolicyLink(_caregiverId) {
        return '/mock/v1/caregiver_pdf_mock.pdf';
    },

    getConsentRequests: async () => {
        const consentRequestsResponse = await fetch(`/mock/v1/consentRequests.json`);

        if (!consentRequestsResponse.ok) {
            console.error(await consentRequestsResponse.text());
            throw new Error(`Unable to fetch consentRequests: ${consentRequestsResponse.status}`);
        }

        const consentRequests = (await consentRequestsResponse.json()) as Array<Array<ConsentRequestObject>>;
        const person = getDebugPerson();

        await delay();

        return consentRequests[person];
    },

    getPlaces: async (query: string, abortSignal: AbortSignal) => {
        const searchResponse = await fetch('/mock/v1/search.json', {
            signal: abortSignal
        });

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as Array<PlacesFilterJSON>;

        await delay();

        return searchData.filter((place) => place.name.toLowerCase().includes(query.toLowerCase()));
    },

    getPlace: async (placeId: string) => {
        const searchResponse = await fetch('/mock/v1/search.json');

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as Array<PlacesFilterJSON>;

        await delay();

        return searchData.find((place) => place.id === placeId) || null;
    },

    getCounties: async () => {
        const searchResponse = await fetch('/mock/v1/geo-counties.json');

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as Array<PlacesFilterJSON>;

        await delay(100);

        return searchData;
    },

    getCountries: async () => {
        const countriesResponse = await fetch('/mock/v1/geo-countries.json');

        if (!countriesResponse.ok) {
            console.error(await countriesResponse.text());
            throw new Error(`Unable to fetch countries: ${countriesResponse.status}`);
        }

        const countriesList = (await countriesResponse.json()) as Array<Country>;

        await delay(100);

        return countriesList;
    },

    getAppointmentCategories: async (clinicId: number) => {
        const appointmentTypeResponse = await fetch('/mock/v1/appointmentCategories.json');

        if (!appointmentTypeResponse.ok) {
            console.error(await appointmentTypeResponse.text());
            throw new Error(`Unable to fetch appointment types for clinic: ${appointmentTypeResponse.status}`);
        }

        const appointmentTypeData = (await appointmentTypeResponse.json()) as Array<Array<AppointmentCategory>>;

        await delay(200);

        return appointmentTypeData[0];
    },
    getAppointmentType: async (clinicId: number, appointmentTypeId: number) => {
        const appointmentTypeResponse = await fetch('/mock/v1/appointmentTypes.json');

        if (!appointmentTypeResponse.ok) {
            console.error(await appointmentTypeResponse.text());
            throw new Error(`Unable to fetch appointment types for clinic: ${appointmentTypeResponse.status}`);
        }

        const appointmentTypes = (await appointmentTypeResponse.json()) as Array<Array<AppointmentType>>;
        await delay(200);
        return appointmentTypes[0].find(({id}) => id === appointmentTypeId) || null;
    },

    getTimeSlots: async (
        clinicId: number,
        appointmentTypeId: number,
        from: Date,
        to: Date,
        abortSignal: AbortSignal
    ) => {
        type MockedSlotsType = 'NORMAL_WEEK' | 'EMPTY_WEEK';

        const timeSlotResponse = await fetch('/mock/v1/timeSlots.json', {
            signal: abortSignal
        });

        if (!timeSlotResponse.ok) {
            console.error(await timeSlotResponse.text());
            throw new Error(`Unable to fetch time slots. Status: ${timeSlotResponse.status}`);
        }
        const timeSlotsData = (await timeSlotResponse.json()) as Record<MockedSlotsType, Array<Array<string>>>;

        await delay(750);

        const weekInterval = eachDayOfInterval({start: from, end: to});
        let times = timeSlotsData['NORMAL_WEEK'];

        if (clinicId === 76) {
            times = timeSlotsData['EMPTY_WEEK'];
            if (+from === +startOfDay(new Date('2025-02-03'))) {
                times = timeSlotsData['NORMAL_WEEK'];
            }
        } else if (clinicId === 177) {
            const weekIsh = getWeek(from) % 10;
            times = weekIsh === 5 ? timeSlotsData['EMPTY_WEEK'] : timeSlotsData['NORMAL_WEEK'];
        } else if (clinicId === 102) {
            // concat year and zeropadded week
            const possibleFuture = parseInt(`${from.getFullYear()}${getWeek(from).toString().padStart(2, '0')}`, 10);
            times = possibleFuture > 202325 ? timeSlotsData['EMPTY_WEEK'] : timeSlotsData['NORMAL_WEEK'];

            if (+from === +startOfDay(new Date('2025-02-03'))) {
                times = timeSlotsData['NORMAL_WEEK'];
            }
        }

        const flatTimes = weekInterval
            .reduce(
                (prev, curr, index) => {
                    prev.push(
                        times[index].map((time) => {
                            const [h, m] = time.split(':');
                            return setMinutes(setHours(curr, parseInt(h, 10)), parseInt(m, 10)).toISOString();
                        })
                    );

                    return prev;
                },
                [] as Array<Array<string>>
            )
            .flat();

        return flatTimes.filter((dateString) => {
            const date = new Date(dateString);
            return isPast(date) === false;
        });
    },

    getNextAvailableTimeSlot: async (clinicId: number, appointmentTypeId: number, from: Date, to: Date) => {
        await delay(250);
        if (clinicId === 177) {
            return null;
        }
        if (isAfter(from, new Date('2025-02-03'))) {
            return null;
        }

        return startOfDay(new Date('2025-02-03')).toISOString();
    },

    getCapacity: async (clinicId: number, abortSignal: AbortSignal, reasonIds: Array<string> = []) => {
        await fetch(`/mock/v1/capacity.json?clinidId=${clinicId}`);

        await delay(150);

        if (clinicId === 72 && reasonIds[0] === '1') {
            return {
                within7Days: 0,
                within30Days: 0
            };
        }
        if (clinicId === 2596 && reasonIds[0] === '1') {
            return {
                first: startOfDay(addDays(new Date(), 40)).toISOString(),
                within7Days: 0,
                within30Days: 0
            };
        }

        return {
            first: startOfDay(addDays(new Date(), Math.ceil(Math.random() * 24))).toISOString(),
            within7Days: Math.floor(Math.random() * 20),
            within30Days: Math.floor(Math.random() * 100)
        };
    },

    reserveTimeSlot: async (_clinicId, _appointmentTypeId, _timeSlot) => {
        const bookingIds = [
            '45079187-587c-45f7-9f23-d03853d13f63',
            '21363b57-fb6e-489a-b135-96869769f8a4',
            'c6da7678-7880-4e55-85d5-213a504d608e',
            '86810114-d778-4a2e-b0cc-6118ec2a3f9a',
            '93c6b499-b979-4fc5-b25e-6229428b9ba2',
            '13e8870f-4251-4789-a1d9-883310847b12'
        ];

        await fetch('/mock/v1/timeSlots.json?RESERVE');

        await delay(500);

        return {
            bookingId: bookingIds[Math.floor(Math.random() * bookingIds.length)],
            sessionDuration: 360
        };
    },

    unreserveTimeSlot: async (_clinicId, _bookingId, _jwt) => {
        await fetch('/mock/v1/timeSlots.json?UNRESERVE');

        await delay(250);
    },

    refreshTimeSlot: async (jwt, clinicId, bookingId) => {
        await fetch('/mock/v1/timeSlots.json?REFRESH');
        await delay(100);

        return;
    },

    getHealthDeclaration: async (healthDeclarationId) => {
        const hdResponse = await fetch('/mock/v1/healthDeclaration.json');

        if (!hdResponse.ok) {
            console.error(await hdResponse.text());
            throw new Error(`Unable to fetch health declaration: ${hdResponse.status}`);
        }

        const countriesList = (await hdResponse.json()) as HealthDeclaration;

        await delay(100);

        return countriesList;
    },

    getDefaultHealthDeclaration: async (_clinicId) => {
        const hdResponse = await fetch('/mock/v1/healthDeclaration.json');

        if (!hdResponse.ok) {
            console.error(await hdResponse.text());
            throw new Error(`Unable to fetch health declaration: ${hdResponse.status}`);
        }

        const healthDeclarations = (await hdResponse.json()) as HealthDeclaration;

        await delay(100);

        return healthDeclarations;
    },

    getPerson: async (jwt: string) => {
        const personResponse = await fetch('/mock/v1/person.json');
        await delay(500);
        const person = getDebugPerson();

        const personData = (await personResponse.json()) as Array<UserJSON>;
        return personData[person] || personData[0];
    },

    getPersonMinimal: async (jwt: string) => {
        const personResponse = await fetch('/mock/v1/person.json?MINIMAL');
        await delay(500);
        const person = getDebugPerson();

        const personData = (await personResponse.json()) as Array<UserJSON>;
        return personData[person] || personData[0];
    },
    finalizeBooking: async (clinicId, bookingId, jwt) => {
        const finalizeResponse = await fetch('/mock/v1/finalize.json');

        if (!finalizeResponse.ok) {
            console.error(await finalizeResponse.text());
            throw new Error(`Unable to finalize booking: ${finalizeResponse.status}`);
        }

        const finalizeData = (await finalizeResponse.json()) as FinalizeBookingResponse;

        await delay(500);

        return finalizeData;
    },

    submitHealthDeclaration: async (clinicId, jwt, data) => {
        const finalizeResponse = await fetch('/mock/v1/finalize.json');

        if (!finalizeResponse.ok) {
            console.error(await finalizeResponse.text());
            throw new Error(`Unable to finalize booking: ${finalizeResponse.status}`);
        }

        (await finalizeResponse.json()) as FinalizeBookingResponse;

        await delay(500);
        return;
    },

    validatePnr: async (pnr: string) => {
        await fetch('/mock/v1/validatePnr.json');

        await delay(50);

        if (pnr.length !== 12) {
            throw new InvalidIdentityError('Invalid pnr');
        }

        const legalGenderNumber = parseInt(pnr.slice(-2, -1), 10);

        return {
            identity: pnr,
            gender: legalGenderNumber % 2 === 0 ? 'female' : 'male',
            age: 42
        };
    },

    getSingleBooking: async (bookingCode, identity) => {
        const booking = await fetch('/mock/v1/bookings.json');

        await delay(50);

        if (bookingCode === '403-403') {
            throw new ForbiddenError('User token is required to be sent to allow this');
        }

        if (bookingCode === '404-404') {
            throw new NotFoundError('No such resource (booking/clinic/etc...) can be found.');
        }

        const person = getDebugPerson();
        const bookings = (await booking.json()) as Array<Array<BookingResponse>>;

        return bookings[person][0] || bookings[0][0];
    },

    getBookings: async (userJwt: string) => {
        const bookingJson = (await (await fetch('/mock/v1/bookings.json')).json()) as Array<Array<BookingResponse>>;

        await delay(50);

        const person = getDebugPerson();
        const bookings = bookingJson[person] || bookingJson[0];

        return bookings;
    },

    patchConsentRequests: async (jwt: string, consentRequestAnswer: ConsentRequestAnswer) => {
        const response = await fetch('/mock/v1/consentRequests.json?UPDATE');

        await delay(50);

        if (!response.ok) {
            console.error(await response.text());
            throw new Error(`Could not update Booking: ${response.status}`);
        }
    },

    patchBooking: async (bookingId, clinicId, bookingData, jwt) => {
        const response = await fetch('/mock/v1/bookings.json?UPDATE');

        await delay(50);

        if (!response.ok) {
            console.error(await response.text());
            throw new Error(`Could not update Booking: ${response.status}`);
        }
    },

    getHistory: async (userJwt: string, userId: string) => {
        let historyJson: Array<HistoryResponse> = [];
        if (userId === '1234567') {
            historyJson = (await (await fetch('/mock/v1/historyChild1.json')).json()) as Array<HistoryResponse>;
        } else if (userId === '1234568') {
            historyJson = (await (await fetch('/mock/v1/historyChild2.json')).json()) as Array<HistoryResponse>;
        } else {
            historyJson = (await (await fetch('/mock/v1/history.json')).json()) as Array<HistoryResponse>;
        }

        await delay(500);

        const history = historyJson;

        return history;
    },

    getVaccineCategories: async () => {
        const vaccineCategoriesResponse = await fetch('/mock/v1/vaccineCategories.json');
        const vaccineCategories = (await vaccineCategoriesResponse.json()) as Array<VaccineCategoryResponse>;

        await delay(2000);

        return vaccineCategories;
    },

    addManualVaccination: async (jwt, userId, payload) => {
        throw new Error('Failed to add manual vaccination');
    },

    editManualVaccination: async (jwt, userId, vaccinationId, payload) => {
        throw new Error('Failed to edit manual vaccination');
    },

    deleteManuallyAddedVaccination: async (userJwt: string, vaccinationId: string, userId?: string) => {
        await delay(50);
    },

    getInfoTexts: async () => {
        const infoTextsResponse = await fetch('/mock/v1/infoTexts.json');

        if (!infoTextsResponse.ok) {
            console.error(await infoTextsResponse.text());
            throw new Error(`Unable to fetch info texts: ${infoTextsResponse.status}`);
        }

        const infoTexts = (await infoTextsResponse.json()) as InfoTextsResponse;

        await delay();

        return infoTexts;
    }
};
