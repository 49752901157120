import {EmailIcon} from '../../../icons/EmailIcon';
import {LocationIcon} from '../../../icons/LocationIcon';
import {PhoneIcon} from '../../../icons/PhoneIcon';
import {WorldIcon} from '../../../icons/WorldIcon';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import {Icon} from 'leaflet';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import './BookingInitialAside.scss';
import {InfoTextsDialog} from 'src/components/info-texts/info-texts-dialog/InfoTextsDialog';
import {useInfoTextsContext} from 'src/context/InfoTextsContext';
import {InfoTextsControl} from 'src/components/info-texts/InfoTextsControl';

export function BookingInitialAside() {
    const {t} = useTranslation();
    const {dataState} = useClinicDetailsContext();
    const {clinic, loading, error} = dataState;
    const [position, setPosition] = useState<[number, number]>();
    const [showFullTextToggle, setShowFullTextToggle] = useState<boolean>(false);

    const {infoTextsState} = useInfoTextsContext();
    const {infoTexts} = infoTextsState;

    const getMapsUrl = () => {
        if (navigator.userAgent.indexOf('Mac') !== -1) {
            return `maps://maps.apple.com/?q=${clinic?.address.split(' ').join('')},${clinic?.city
                .split(' ')
                .join('')}`;
        } else {
            return `https://maps.google.com/maps/search/?api=1&query=${clinic?.address
                .split(' ')
                .join('')}+${clinic?.city.split(' ').join('')}`;
        }
    };

    const mapServiceEndpoint =
        process.env.REACT_APP_CLINIC_MAP_SERVICE_ENDPOINT || 'https://maps-test.mittvaccin.se/tile';

    const hasVisitOption = () => clinic?.hasDropin || clinic?.hasTimebook;

    const hiddenWelcomeText = (text: string | undefined, showFullTextToggle: boolean) => {
        if (text && text.length > 320 && showFullTextToggle) {
            return text.substring(0, 300).concat('...');
        }
        return text;
    };

    useEffect(() => {
        if (clinic?.position?.lat && clinic?.position?.lon) {
            setPosition([clinic.position.lat as number, clinic.position.lon as number]);
            if (clinic?.welcomeText && clinic?.welcomeText.length > 320) {
                setShowFullTextToggle(true);
            }
        }
    }, [clinic]);

    const getValidLink = (link: string) => {
        return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
    };

    return (
        <aside className='aside-content' aria-label={clinic ? t('clinic_aside.aria_label') : ''}>
            {loading && !error && <div className='aside-content__loading'></div>}
            {!loading && clinic && !error && (
                <div className='aside-content__container aside-content__container--initial'>
                    <InfoTextsDialog />

                    {infoTexts && (
                        <section className='aside-padding info_texts_wrapper'>
                            <h3>{t('clinic_aside.info_texts')}</h3>

                            <InfoTextsControl />
                        </section>
                    )}

                    {hasVisitOption() && (
                        <section className='aside-padding'>
                            <h3>{t('clinic_aside.welcome')}</h3>
                            <p className='wrap-html'>
                                {hiddenWelcomeText(clinic?.welcomeText, showFullTextToggle)}{' '}
                                {showFullTextToggle && (
                                    <button
                                        className='primary'
                                        onClick={() => setShowFullTextToggle(!showFullTextToggle)}
                                    >
                                        {t('clinic_aside.read_more')}
                                    </button>
                                )}
                            </p>
                        </section>
                    )}
                    <section className='contact-us'>
                        <h3 className='aside-padding'>{t('clinic_aside.contact_us')}</h3>
                        <div className='links'>
                            <p>
                                {clinic?.email && (
                                    <a href={`mailto:${clinic.email.split(' ').join('')}`}>
                                        <EmailIcon />
                                        {clinic.email}
                                    </a>
                                )}
                                {clinic?.phone && (
                                    <a href={`tel:${clinic.phone.split(' ').join('')}`}>
                                        <PhoneIcon />
                                        {clinic.phone}
                                    </a>
                                )}
                                {clinic?.websiteUrl && (
                                    <a href={getValidLink(clinic.websiteUrl)}>
                                        <WorldIcon />
                                        {t('clinic_aside.clinic_webpage')}
                                    </a>
                                )}
                                {clinic?.address && (
                                    <a href={getMapsUrl()}>
                                        <LocationIcon />
                                        {clinic?.address + `, ` + clinic?.city + ` ` + clinic?.zip}
                                    </a>
                                )}
                            </p>
                        </div>
                    </section>
                    {position && (
                        <div className='aside-map'>
                            <MapContainer
                                className='map_container'
                                center={position}
                                zoom={15}
                                scrollWheelZoom={false}
                                dragging={false}
                            >
                                <TileLayer
                                    attribution='<span aria-hidden="true" >&copy; <a href="https://www.openstreetmap.org/copyright" tabindex=-1>OpenStreetMap</a> contributors</span>'
                                    url={`${mapServiceEndpoint}/{z}/{x}/{y}.png`}
                                />
                                <Marker
                                    alt={t(`map_position_marker.text`) as string}
                                    position={position}
                                    icon={
                                        new Icon({
                                            className: 'position_map_marker',
                                            iconUrl: '/map_pin_small@2x.png',
                                            iconSize: [48, 56]
                                        })
                                    }
                                />
                            </MapContainer>
                        </div>
                    )}
                </div>
            )}
        </aside>
    );
}
