import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorEvent, LatLng, LatLngBounds, latLngBounds, LocationEvent} from 'leaflet';
import {ErrorBoundary} from 'react-error-boundary';

import {LoadIndicator} from '../layout/loader/LoadIndicator';
import {MapModal} from '../layout/map-modal/MapModal';

import {Button} from '../form/button/Button';
import {Search} from '../form/search/Search';

import {FallbackError} from '../errors/FallbackError';

import {LeafletMapContainer} from '../map/map-container/LeaflefMapContainer';
import {PositionMarker} from '../map/marker/PositionMarker';
import {VisibleClinicsLayer} from '../map/map-container/VisibleClinicsLayer';
import {LocationErrorCodes, MapControls} from '../map/map-controls/MapControls';

import {commonElem, isLocationWithinBoundingbox} from '../../services/filtering/filtering';
import {fetchPlace} from '../../services/api/api';
import {PlaceFilterMap, SearchFilterMap, useSearchFilters} from '../../hooks/useSearchFilters';

import './style.scss';
import {MoreFilters} from './more-filters/MoreFilters';
import {CountyFilters} from './county-filters/CountyFilters';
import {ClinicObjectSlim} from '../../services/api/types/types';
import {FilterSkeleton} from '../layout/skeleton/filter-skeleton/FilterSkeleton';
import {SWE_BOUNDING_BOX} from '../../services/geo/constants';
import {ErrorDialog, UnionErrorType} from './error-dialog/ErrorDialog';
import {ListStatus, UiActions, UiLoadStatusActions, UiStringActions, useUiContext} from '../../context/UiContext';
import {useMapDataContext, MapLocationActions, MapBooleanActions} from '../../context/MapDataContext';
import {ListModal} from './list-modal/ListModal';
import {CSSTransition} from 'react-transition-group';
import {MapPanListener} from '../map/map-pan-listener/MapPanListener';
import {MapPolicyMenu} from '../map/map-policy-menu/MapPolicyMenu';
import {PickVisitCategory, VisitCategory} from './components/PickVisitCategory';
import {MobileSearchFooter} from './components/MobileSearchFooter';
import {SearchFooter} from './components/SearchFooter';
import {PickVisitReasons} from './reason-filters/PickVisitReasons';
import {InfoTextsDialog} from '../info-texts/info-texts-dialog/InfoTextsDialog';
import {InfoTextsControl} from '../info-texts/InfoTextsControl';

export type LoadStatus = 'loading' | 'done' | 'error' | 'hold';
type SearchMode = 'auto' | 'manual';

export function Main() {
    const {
        searchParams,
        placeFilters,
        setPlaceFilters,
        addFilter,
        removeFilter,
        setFilter,
        addPlaceFilter,
        removePlaceFilter,
        setOthers,
        searchParamsCount,
        clearParams,
        clearParamsByType
    } = useSearchFilters();

    const {state, dispatch: uiDispatch} = useUiContext();
    const {listStatus, popupOpenId, smallDevice, searchExtended, clinicLoadStatus, filtersLoadStatus, visibleClinics} =
        state;
    const {state: mapDataState, dispatch: mapDataDispatch} = useMapDataContext();
    const {careGivers, visitReasons, counties, clinics} = mapDataState;

    const {t} = useTranslation();

    const [selectedCategory, setSelectedCategory] = useState<VisitCategory>('vaccination');
    const [filteredClinics, setFilteredClinics] = useState<Array<ClinicObjectSlim>>([]);
    const [mapCenterPoint, setMapCenterPoint] = useState<LatLng | null>(null);
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [searchResultsIsExpanded, setSearchResultsIsExpanded] = useState(false);

    const [initialFilterDone, setInitialFilterDone] = useState(false);
    const [mapBounds, setMapBounds] = useState<LatLngBounds | null>(() => {
        if (searchParams.place.length === 0 && searchParams.caregiver.length === 0) {
            // Default Sweden boundingbox
            return SWE_BOUNDING_BOX;
        }

        return null;
    });

    const [error, setError] = useState<UnionErrorType>(null);

    const mapRef = useRef<L.Map>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const firstLoad = useRef(true);
    const placeFilterChanged = useRef(false);

    const searchMode: SearchMode = smallDevice ? 'manual' : 'auto';
    const reasonQuery = searchParams.reason.length > 0 ? `?reason=${searchParams.reason.join('&reason=')}` : '';
    const showList = listStatus === ListStatus.VISIBLE || listStatus === ListStatus.FORCED_VISIBLE;

    const onLocationError = (e: ErrorEvent, retryFunction?: () => void) => {
        uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'done'});

        switch (e.code) {
            case LocationErrorCodes.TIMEOUT:
                setError(['location_timeout', retryFunction !== undefined ? retryFunction : () => {}]);
                break;
            case LocationErrorCodes.PERMISSION_DENIED:
            default:
                setError('location_permission');
        }
    };

    const onLocationFound = (e: LocationEvent) => {
        mapDataDispatch({type: MapLocationActions.SET_USER_LOCATION, value: e.latlng});
        mapDataDispatch({type: MapBooleanActions.SET_HAS_GEO_PERMISSION, value: true});
        uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'done'});
    };

    const onLocationSearch = () => {
        mapDataDispatch({type: MapLocationActions.SET_USER_LOCATION, value: null});
        uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'loading'});
    };

    // Apply loading state to initiate filter and clinics loading
    const onMapReady = () => {
        uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'loading'});
        uiDispatch({type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS, value: 'loading'});
    };

    const filterClinics = useCallback(
        (searchParams: SearchFilterMap) => {
            setError(null);

            // TODO: Put this in a better place, also probably optimize somehow
            const filtered = clinics.filter((clinic) => {
                const shouldInclude = [];
                if (searchParams.place.length > 0) {
                    shouldInclude.push(
                        searchParams.place.some((placeId) => {
                            if (!placeFilters[placeId]) {
                                return true;
                            }
                            return isLocationWithinBoundingbox(placeFilters[placeId].boundingBox, clinic);
                        })
                    );
                }
                if (searchParams.other.includes('hasDropin')) {
                    shouldInclude.push(clinic.hasDropin);
                }
                if (searchParams.clinic.length) {
                    shouldInclude.push(searchParams.clinic.includes(clinic.id));
                }
                if (searchParams.caregiver.length) {
                    const foundSelf = searchParams.caregiver.includes(clinic.careGiverId?.toString());
                    const foundUG = searchParams.caregiver.includes(clinic.principalCareGiverId?.toString());
                    shouldInclude.push(foundSelf || foundUG);
                }
                if (searchParams.reason.length) {
                    shouldInclude.push(
                        commonElem(searchParams.reason, clinic.availableVisitReasonIds).length ===
                            searchParams.reason.length
                    );
                }

                return !shouldInclude.includes(false);
            });

            setFilteredClinics(filtered);

            // Initial setting of viewport when searchparams are present
            const hasSearchParams =
                searchParams.reason.length +
                    searchParams.other.length +
                    searchParams.place.length +
                    searchParams.caregiver.length >
                0;
            if (firstLoad.current === true && hasSearchParams && filtered.length > 0) {
                setMapBounds(latLngBounds(filtered.map((clinic) => [clinic.location.lat, clinic.location.lng])));
                if (!smallDevice) {
                    uiDispatch({type: UiStringActions.SET_LIST_STATUS, value: ListStatus.FORCED_VISIBLE});
                }
            }
            firstLoad.current = false;

            // Place filter was added since previous filter, set viewport
            if (placeFilterChanged.current === true) {
                placeFilterChanged.current = false;

                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
                if (filtered.length > 0) {
                    setMapBounds(latLngBounds(filtered.map((clinic) => [clinic.location.lat, clinic.location.lng])));
                }
            }
            if (!hasSearchParams) {
                setMapBounds(SWE_BOUNDING_BOX);
            }

            if (filtered.length === 0) {
                setError('empty_result');
            }
        },
        [clinics, placeFilters]
    );

    useEffect(() => {
        if (smallDevice || firstLoad.current === true) {
            return;
        }

        if (listStatus === ListStatus.HIDDEN && visibleClinics.length <= 50) {
            uiDispatch({type: UiStringActions.SET_LIST_STATUS, value: ListStatus.FORCED_VISIBLE});
        }
    }, [visibleClinics.length, listStatus, uiDispatch, smallDevice]);

    useEffect(() => {
        if (filtersLoadStatus === 'hold' || filtersLoadStatus === 'loading') {
            return;
        }

        const firstSelectedReason = visitReasons.find(({id}) => {
            return searchParams.reason.includes(id);
        });
        if (firstSelectedReason) {
            setSelectedCategory(firstSelectedReason.category);
        }
    }, [filtersLoadStatus, searchParams.reason, visitReasons]);

    const toggleSearchView = useCallback(() => {
        if (smallDevice) {
            scrollRef.current?.scrollTo({top: 0});
            uiDispatch({type: UiActions.SET_EXTEND_SEARCH, value: !searchExtended});
        } else {
            uiDispatch({type: UiActions.SET_EXTEND_SEARCH, value: true});
        }
    }, [searchExtended, smallDevice, uiDispatch]);

    useEffect(() => {
        if (smallDevice) {
            uiDispatch({type: UiActions.SET_EXTEND_SEARCH, value: false});
        }
        return () => {
            uiDispatch({type: UiStringActions.SET_LIST_STATUS, value: ListStatus.HIDDEN});
        };
    }, [smallDevice, uiDispatch]);

    // Initial filters on load
    useEffect(() => {
        if (filtersLoadStatus === 'error' || filtersLoadStatus === 'done' || filtersLoadStatus === 'hold') {
            return;
        }

        const loadFilters = async () => {
            uiDispatch({type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS, value: 'loading'});

            try {
                if (searchParams.place.length > 0) {
                    const placeFilters: PlaceFilterMap = {};
                    for (let i = 0; i < searchParams.place.length; i++) {
                        const placeId = searchParams.place[i];
                        placeFilters[placeId] = await fetchPlace(placeId);
                    }
                    setPlaceFilters(placeFilters);
                }
            } catch (error) {
                uiDispatch({type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS, value: 'error'});
            }
        };

        loadFilters();
    }, [filtersLoadStatus, searchParams.place, setPlaceFilters, uiDispatch]);

    useEffect(() => {
        if (clinics.length === 0 || ['loading', 'hold'].includes(filtersLoadStatus)) {
            return;
        }
        if (!initialFilterDone) {
            setInitialFilterDone(true);
        }

        filterClinics(searchParams);
    }, [clinics.length, filterClinics, filtersLoadStatus, initialFilterDone, searchMode, searchParams]);

    return (
        <>
            <main id='main-map-container'>
                <ErrorBoundary
                    FallbackComponent={FallbackError}
                    onReset={() => {
                        /** reset the state of your app so the error doesn't happen again */
                    }}
                >
                    <InfoTextsDialog />

                    <div className={`info_texts_control ${showList ? 'info_texts_control--shifted' : ''}`}>
                        <InfoTextsControl />
                    </div>

                    <div
                        className={`${showList ? 'main--list-modal-visible' : ''} ${popupOpenId ? 'search_modal--hide' : ''}`}
                    >
                        <LoadIndicator show={clinicLoadStatus === 'loading' || filtersLoadStatus === 'loading'} />

                        {(clinicLoadStatus === 'error' || error !== null) && (
                            <ErrorDialog error={error} clear={() => setError(null)} />
                        )}

                        <MapModal
                            id='search-modal'
                            className={`search_modal${searchExtended ? ' search_modal--up' : ''} ${
                                popupOpenId ? 'search_modal--hide' : ''
                            }`}
                        >
                            {['loading', 'hold'].includes(filtersLoadStatus) && (
                                <section className='map_modal__content search_modal__skeleton'>
                                    <FilterSkeleton />
                                </section>
                            )}

                            {filtersLoadStatus === 'error' && (
                                <div className='map_modal__content search_modal__error'>
                                    <p>
                                        <b>{t('error_filter_loading_title.text')}</b>
                                        <br />
                                        {t('error_filter_loading_message.text')}
                                    </p>
                                    <Button
                                        tabIndex={searchExtended ? 0 : -1}
                                        onClick={() =>
                                            uiDispatch({
                                                type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS,
                                                value: 'loading'
                                            })
                                        }
                                    >
                                        {t('button_try_again.text')}
                                    </Button>
                                </div>
                            )}

                            {filtersLoadStatus === 'done' && (
                                <>
                                    <div
                                        id='search-modal-scroll'
                                        ref={scrollRef}
                                        className='map_modal__scroll'
                                        aria-hidden={searchExtended === false}
                                    >
                                        <div id='search-modal' className='map_modal__content search_modal__search'>
                                            <Search
                                                searchParams={searchParams}
                                                reasonFilters={visitReasons}
                                                placeFilters={placeFilters}
                                                clinicFilters={clinics}
                                                careGiverFilters={careGivers}
                                                disableTabNavigation={false}
                                                onResultsExpanded={(isActive) => {
                                                    if (isActive !== searchResultsIsExpanded) {
                                                        setSearchResultsIsExpanded(isActive);
                                                    }
                                                }}
                                                onFocusUpdate={(isFocused) => {
                                                    isFocused &&
                                                        uiDispatch({
                                                            type: UiActions.SET_EXTEND_SEARCH,
                                                            value: true
                                                        });
                                                    setSearchIsFocused(isFocused);
                                                }}
                                                onClick={() => {
                                                    uiDispatch({
                                                        type: UiActions.SET_EXTEND_SEARCH,
                                                        value: !searchExtended
                                                    });
                                                }}
                                                onToggleFilter={(type, filterId, active) => {
                                                    if (type === 'reason') {
                                                        const reasonCategory = visitReasons.find(
                                                            ({id}) => id === filterId
                                                        )?.category;
                                                        // Clear all other reason filters when reason category changes
                                                        if (reasonCategory !== selectedCategory) {
                                                            setFilter('reason', [filterId]);
                                                        } else {
                                                            active
                                                                ? addFilter('reason', filterId)
                                                                : removeFilter('reason', filterId);
                                                        }
                                                    } else {
                                                        active
                                                            ? addFilter(type, filterId)
                                                            : removeFilter(type, filterId);
                                                    }
                                                }}
                                                onAddPlaceFilter={(filterId, place) => {
                                                    placeFilterChanged.current = true;
                                                    addPlaceFilter(filterId, place);
                                                }}
                                                onRemovePlaceFilter={(filterId) => {
                                                    placeFilterChanged.current = true;
                                                    removePlaceFilter(filterId);
                                                }}
                                            />
                                        </div>
                                        {!searchResultsIsExpanded && (
                                            <div>
                                                <PickVisitCategory
                                                    selectedCategory={selectedCategory}
                                                    onChange={(visitCategory) => {
                                                        if (visitCategory === selectedCategory) {
                                                            return;
                                                        }
                                                        setSelectedCategory(visitCategory);

                                                        if (searchParams.reason.length > 0) {
                                                            clearParamsByType('reason');
                                                        }
                                                    }}
                                                />

                                                {selectedCategory !== null && (
                                                    <PickVisitReasons
                                                        selectedCategory={selectedCategory}
                                                        searchParams={searchParams}
                                                        addReasonFilter={(id) => addFilter('reason', id)}
                                                        removeReasonFilter={(id) => removeFilter('reason', id)}
                                                    />
                                                )}

                                                <fieldset className='map_modal__content'>
                                                    <legend className='off-screen'>{t('county_heading.text')}</legend>
                                                    <h2>{t('county_heading.text')}</h2>
                                                    <CountyFilters
                                                        countyFilters={counties}
                                                        activeFilters={searchParams.place}
                                                        disableTabNavigation={false}
                                                        onToggleFilter={(id, place, checked) => {
                                                            placeFilterChanged.current = true;
                                                            if (checked) {
                                                                addPlaceFilter(id, place);
                                                            } else {
                                                                removePlaceFilter(id);
                                                            }
                                                        }}
                                                    />
                                                </fieldset>
                                                <fieldset className='map_modal__content'>
                                                    <legend className='off-screen'>{t('more_options.text')}</legend>
                                                    <h2>{t('more_options.text')}</h2>
                                                    <MoreFilters
                                                        activeFilters={searchParams.other}
                                                        disableTabNavigation={false}
                                                        onToggleFilter={(activeFilters) => setOthers(activeFilters)}
                                                    />
                                                </fieldset>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className='map_modal__content search_modal__footer'
                                        aria-hidden={searchExtended === false}
                                    >
                                        {searchMode === 'manual' && !searchIsFocused && !searchResultsIsExpanded && (
                                            <MobileSearchFooter
                                                clearParams={() => {
                                                    setSelectedCategory('vaccination');
                                                    clearParamsByType('reason');
                                                    clearParams();
                                                }}
                                                paramsCount={searchParamsCount()}
                                                toggleSearchView={toggleSearchView}
                                            />
                                        )}
                                        {searchMode === 'auto' && searchParamsCount() > 0 && (
                                            <SearchFooter
                                                clearParams={() => {
                                                    setSelectedCategory('vaccination');
                                                    clearParamsByType('reason');
                                                    clearParams();
                                                }}
                                                paramsCount={searchParamsCount()}
                                                toggleSearchView={toggleSearchView}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </MapModal>
                        <CSSTransition
                            unmountOnExit
                            timeout={{enter: 0, exit: 200}}
                            in={showList}
                            classNames={'list_modal'}
                        >
                            <ListModal mapCenterPoint={mapCenterPoint} reasonParams={searchParams.reason} />
                        </CSSTransition>
                    </div>
                    <LeafletMapContainer ref={mapRef} onReady={onMapReady} mapBounds={mapBounds}>
                        <MapControls
                            onLocationFound={onLocationFound}
                            onLocationSearch={onLocationSearch}
                            onLocationError={onLocationError}
                        />
                        <MapPanListener onMoveEnd={(centerPoint) => setMapCenterPoint(centerPoint)} />
                        <MapPolicyMenu />
                        <VisibleClinicsLayer reasonQuery={reasonQuery} clinics={filteredClinics} />

                        {mapDataState.userLocation && <PositionMarker position={mapDataState.userLocation} />}
                    </LeafletMapContainer>
                </ErrorBoundary>
            </main>
        </>
    );
}
