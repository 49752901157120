import {AlertIcon} from './AlertIcon';
import {ErrorIcon} from './ErrorIcon';
import {WarningIcon} from './WarningIcon';

type IconProps = {
    type: string;
    width?: string;
    height?: string;
    onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
};

const getInfoIndicatorIcon = (props: IconProps) => {
    switch (props.type) {
        case 'INFO':
            return <WarningIcon />;
        case 'ALERT':
            return <AlertIcon />;
        case 'ERROR':
            return <ErrorIcon />;
    }
};

export function InfoTextsIcon(props: IconProps) {
    return <>{getInfoIndicatorIcon(props)}</>;
}
