import {InfoTextsIcon} from '../icons/InfoTextsIcon';
import {InfoTextsActions, useInfoTextsContext} from 'src/context/InfoTextsContext';
import './InfoTextsControl.scss';
import {t} from 'i18next';

export function InfoTextsControl() {
    const {infoTextsState, dispatch} = useInfoTextsContext();
    const {uniqueTypes, infoTextsDisplayDialog} = infoTextsState;

    return (
        <div className='info_texts_icon_wrapper'>
            {uniqueTypes?.map((type, index) => {
                const areaLabel = `info_texts_icon_` + type.toLowerCase() + '.text';
                return (
                    <div
                        className={`info_texts_icon info_texts_icon_` + type.toLowerCase()}
                        key={`info_texts_icon_${index}`}
                    >
                        <button
                            aria-label={t(areaLabel)}
                            onClick={() => {
                                dispatch({type: InfoTextsActions.SET_DISPLAY_DIALOG, value: !infoTextsDisplayDialog});
                            }}
                        >
                            <InfoTextsIcon type={type} />
                        </button>
                    </div>
                );
            })}
        </div>
    );
}
