import {format} from '../../../lib/dates/format';
import {
    AccountJSON,
    ApiImplementation,
    CaregiverJSON,
    ClinicJSON,
    ClinicJSONSlim,
    ReasonFilterJSON,
    PlacesFilterJSON,
    AppointmentCategory,
    ReserveBookingJSON,
    HealthDeclaration,
    Country,
    UserJSON,
    FinalizeBookingResponse,
    CapacityResponse,
    PnrJSON,
    FinalizeBookingRequest,
    ApiErrorResponse,
    AppointmentType,
    BookingResponse,
    ConsentRequestObject,
    UserMinimalJSON,
    HistoryResponse,
    VaccineCategoryResponse,
    InfoTextsResponse
} from '../types/types';

interface ApiConfig {
    baseUrl: string;
    apiKey: string;
}

const config: ApiConfig = {
    baseUrl: process.env.REACT_APP_CLINIC_API_ENDPOINT || '',
    apiKey: process.env.REACT_APP_CLINIC_API_KEY || ''
};

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NotFoundError';
    }
}

export class InvalidIdentityError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InvalidIdentityError';
    }
}
export class ApiError extends Error {
    errorCode: string;
    constructor(message: string, apiErrorResponse: ApiErrorResponse) {
        super(message);
        this.name = 'ApiError';
        this.errorCode = apiErrorResponse.errorCode;
    }
}

export class ForbiddenError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ForbiddenError';
    }
}

export class UnauthorizedError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'UnauthorizedError';
    }
}

const fetchMaintMode = async function (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> {
    const res = await fetch(input, init);
    if (res.status == 503) {
        const fromUrl = document.location.pathname;
        document.location = `/maintmode/?from=${fromUrl}`;
    }
    return res;
};

export const fetchApi: ApiImplementation = {
    getVisitReasons: async () => {
        const {baseUrl, apiKey} = config;

        const visitReasonsResponse = await fetchMaintMode(`${baseUrl}/v1/visitReasons`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!visitReasonsResponse.ok) {
            console.error(await visitReasonsResponse.text());
            throw new Error(`Unable to fetch visitReasons: ${visitReasonsResponse.status}`);
        }

        const visitReasons = (await visitReasonsResponse.json()) as Array<ReasonFilterJSON>;

        return visitReasons;
    },

    getCaregivers: async () => {
        const {baseUrl, apiKey} = config;
        const careGiversResponse = await fetchMaintMode(`${baseUrl}/v1/careGivers`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!careGiversResponse.ok) {
            console.error(await careGiversResponse.text());
            throw new Error(`Unable to fetch careGivers: ${careGiversResponse.status}`);
        }

        const careGivers = (await careGiversResponse.json()) as Array<CaregiverJSON>;

        return careGivers;
    },

    caregiverCookiePolicyLink(caregiverId: number) {
        const {baseUrl} = config;
        return `${baseUrl}/v1/careGivers/${caregiverId}/cookiePolicy`;
    },

    getClinics: async () => {
        const {baseUrl, apiKey} = config;
        const clinicsResponse = await fetchMaintMode(`${baseUrl}/v1/clinics`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!clinicsResponse.ok) {
            console.error(await clinicsResponse.text());
            throw new Error(`Unable to fetch clinics: ${clinicsResponse.status}`);
        }

        const clinics = (await clinicsResponse.json()) as Array<ClinicJSONSlim>;

        return clinics;
    },

    getClinic: async (clinicId: number) => {
        const {baseUrl, apiKey} = config;
        const clinicResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!clinicResponse.ok) {
            console.error(await clinicResponse.text());

            if (clinicResponse.status === 404) {
                throw new NotFoundError(`Unable to fetch clinic: ${clinicResponse.status}`);
            }

            throw new Error(`Unable to fetch clinic: ${clinicResponse.status}`);
        }
        const clinic = (await clinicResponse.json()) as ClinicJSON;

        return clinic;
    },
    getConsentRequests: async (jwt: string) => {
        const {baseUrl, apiKey} = config;

        const consentRequestsResponse = await fetchMaintMode(`${baseUrl}/v1/user/consents`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });
        const consentRequests = (await consentRequestsResponse.json()) as Array<ConsentRequestObject>;
        return consentRequests;
    },

    getPlaces: async (query: string, abortSignal: AbortSignal) => {
        const {baseUrl, apiKey} = config;
        const searchResponse = await fetchMaintMode(`${baseUrl}/v1/geopos/?limit=3&q=${query}`, {
            signal: abortSignal,
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as Array<PlacesFilterJSON>;

        return searchData;
    },

    getPlace: async (placeId: string) => {
        const {baseUrl, apiKey} = config;
        const searchResponse = await fetchMaintMode(`${baseUrl}/v1/geopos/${placeId}`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as PlacesFilterJSON;

        return searchData || null;
    },

    getCounties: async () => {
        const {baseUrl, apiKey} = config;
        const searchResponse = await fetchMaintMode(`${baseUrl}/v1/geopos/?category=county`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!searchResponse.ok) {
            console.error(await searchResponse.text());
            throw new Error(`Unable to perform search request: ${searchResponse.status}`);
        }

        const searchData = (await searchResponse.json()) as Array<PlacesFilterJSON>;

        return searchData;
    },

    getCountries: async () => {
        const {baseUrl, apiKey} = config;
        const countriesResponse = await fetchMaintMode(`${baseUrl}/v1/countries`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!countriesResponse.ok) {
            console.error(await countriesResponse.text());
            throw new Error(`Unable to fetch countries: ${countriesResponse.status}`);
        }

        const countriesList = (await countriesResponse.json()) as Array<Country>;

        return countriesList;
    },

    getAppointmentCategories: async (clinicId: number) => {
        const {baseUrl, apiKey} = config;
        const appointmentTypeResponse = await fetchMaintMode(
            `${baseUrl}/v1/clinics/${clinicId}/appointmentCategories`,
            {
                method: 'get',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (!appointmentTypeResponse.ok) {
            console.error(await appointmentTypeResponse.text());
            throw new Error(
                `Unable to fetch appointment categories for clinic. Status: ${appointmentTypeResponse.status}`
            );
        }

        const appointmentTypeData = (await appointmentTypeResponse.json()) as Array<AppointmentCategory>;

        return appointmentTypeData;
    },
    getAppointmentType: async (clinicId: number, appointmentTypeId: number) => {
        const {baseUrl, apiKey} = config;

        // v1/clinics/{clinicId}/appointmentTypes/{typeId}/timeSlots
        const appointmentTypeResponse = await fetchMaintMode(
            `${baseUrl}/v1/clinics/${clinicId}/appointmentTypes/${appointmentTypeId}`,
            {
                method: 'get',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (!appointmentTypeResponse.ok) {
            console.error(await appointmentTypeResponse.text());
            throw new Error(`Unable to fetch time slots for clinic. Status: ${appointmentTypeResponse.status}`);
        }

        const appointmentTypeData = (await appointmentTypeResponse.json()) as AppointmentType;

        return appointmentTypeData;
    },
    getTimeSlots: async (
        clinicId: number,
        appointmentTypeId: number,
        from: Date,
        to: Date,
        abortSignal: AbortSignal
    ) => {
        const {baseUrl, apiKey} = config;

        const spanQuery = new URLSearchParams({
            from: format(from, 'yyyy-MM-dd'),
            to: format(to, 'yyyy-MM-dd')
        });

        // v1/clinics/{clinicId}/appointmentTypes/{typeId}/timeSlots
        const timeSlotResponse = await fetchMaintMode(
            `${baseUrl}/v1/clinics/${clinicId}/appointmentTypes/${appointmentTypeId}/timeSlots?${spanQuery}`,
            {
                method: 'get',
                signal: abortSignal,
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (!timeSlotResponse.ok) {
            console.error(await timeSlotResponse.text());
            throw new Error(`Unable to fetch time slots for clinic. Status: ${timeSlotResponse.status}`);
        }

        const timeSlotsData = (await timeSlotResponse.json()) as Array<string>;

        return timeSlotsData;
    },

    getNextAvailableTimeSlot: async (clinicId: number, appointmentTypeId: number, from: Date, to: Date) => {
        const {baseUrl, apiKey} = config;
        const spanQuery = new URLSearchParams({
            from: format(from, 'yyyy-MM-dd'),
            to: format(to, 'yyyy-MM-dd')
        });

        // v1/clinics/{clinicId}/appointmentTypes/{typeId}/firstTimeSlot?from=YYYY-MM-DD&to=YYYY-MM-DD
        const timeSlotResponse = await fetchMaintMode(
            `${baseUrl}/v1/clinics/${clinicId}/appointmentTypes/${appointmentTypeId}/firstTimeSlot?${spanQuery}`,
            {
                method: 'get',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (!timeSlotResponse.ok) {
            console.error(await timeSlotResponse.text());
            throw new Error(`Unable to fetch next available time slot for clinic. Status: ${timeSlotResponse.status}`);
        }

        const timeSlotsData = (await timeSlotResponse.json()) as Array<string>;

        return timeSlotsData.length > 0 ? timeSlotsData[0] : null;
    },

    getCapacity: async (clinicId: number, abortSignal: AbortSignal, reasonIds?: Array<string>) => {
        const {baseUrl, apiKey} = config;
        const spanQuery = new URLSearchParams();
        if (reasonIds?.length) {
            spanQuery.append('visitReasonIds', reasonIds.join(','));
        }

        const capacityResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/capacity?${spanQuery}`, {
            method: 'get',
            signal: abortSignal,
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!capacityResponse.ok) {
            console.error(await capacityResponse.text());
            throw new Error(`Unable to fetch next available time slot for clinic. Status: ${capacityResponse.status}`);
        }

        return (await capacityResponse.json()) as CapacityResponse;
    },

    reserveTimeSlot: async (clinicId, appointmentTypeId, timeSlot) => {
        const {baseUrl, apiKey} = config;

        // v1/clinics/{clinicId}/bookings
        const bookingResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/bookings`, {
            method: 'post',
            body: JSON.stringify({
                appointmentTypeId,
                when: format(timeSlot, `yyyy-MM-dd'T'HH:mm:ssxxx`)
            }),
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!bookingResponse.ok) {
            //console.error(await bookingResponse.text())
            const errorResponse = (await bookingResponse.json()) as ApiErrorResponse;
            throw new ApiError(
                `Unable to reserve time slot for clinic. Status: ${bookingResponse.status}`,
                errorResponse
            );
        }

        const bookingData = (await bookingResponse.json()) as ReserveBookingJSON;

        return {
            bookingId: bookingData.id,
            sessionDuration: bookingData.sessionDuration
        };
    },

    unreserveTimeSlot: async (clinicId: number, bookingId: string, jwt: string | null = null) => {
        const {baseUrl, apiKey} = config;

        // v1/clinics/{clinicId}/bookings
        const bookingResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/bookings/${bookingId}`, {
            method: 'delete',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                ...(jwt !== null && {'x-user-token': jwt})
            }
        });

        if (!bookingResponse.ok) {
            if (bookingResponse.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            console.error(await bookingResponse.text());
            throw new Error(`Unable to clear time slot for clinic. Status: ${bookingResponse.status}`);
        }
    },

    refreshTimeSlot: async (jwt: string, clinicId: number, bookingId: string) => {
        const {baseUrl, apiKey} = config;
        // v1/clinics/{clinicId}/bookings/{bookingId}/extend
        const bookingResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/bookings/${bookingId}/extend`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });

        if (!bookingResponse.ok) {
            if (bookingResponse.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            console.error(await bookingResponse.text());
            throw new Error(`Unable to clear time slot for clinic. Status: ${bookingResponse.status}`);
        }
    },

    getHealthDeclaration: async (healthDeclarationId) => {
        const {baseUrl, apiKey} = config;

        // v1/clinics/{clinicId}/healthDeclDef/{hdId}
        const hdResponse = await fetchMaintMode(`${baseUrl}/v1/healthDeclDef/${healthDeclarationId}`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!hdResponse.ok) {
            console.error(await hdResponse.text());
            throw new Error(`Unable to reserve time slot for clinic. Status: ${hdResponse.status}`);
        }

        const healthDeclaration = (await hdResponse.json()) as HealthDeclaration;

        return healthDeclaration;
    },

    getDefaultHealthDeclaration: async (clinicId) => {
        const {baseUrl, apiKey} = config;

        // v1/clinics/{clinicId}/healthDeclDef/default
        const hdResponse = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/healthDeclDef/default`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!hdResponse.ok) {
            console.error(await hdResponse.text());
            throw new Error(`Unable to fetch default health declaration. Status: ${hdResponse.status}`);
        }

        const healthDeclaration = (await hdResponse.json()) as HealthDeclaration;

        return healthDeclaration;
    },

    getPerson: async (jwt: string) => {
        const {baseUrl, apiKey} = config;

        const personResponse = await fetchMaintMode(`${baseUrl}/v1/user/profile`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });
        const personData = (await personResponse.json()) as UserJSON;
        return personData;
    },
    getPersonMinimal: async (jwt: string) => {
        const {baseUrl, apiKey} = config;

        const personResponse = await fetchMaintMode(`${baseUrl}/v1/user/profile/minimal`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });
        const personData = (await personResponse.json()) as UserMinimalJSON;
        return personData;
    },
    getAccount: async (jwt: string) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/account`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const errorResponse = (await response.json()) as ApiErrorResponse;
            throw errorResponse;
        }
        const accountData = (await response.json()) as AccountJSON;
        return accountData;
    },
    createOrUpdateAccount: async (jwt: string, data: AccountJSON) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/account`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: data.email,
                mobile: data.mobile,
                preferredContact: data.preferredContact
            })
        });
        if (!response.ok) {
            const errorResponse = (await response.json()) as ApiErrorResponse;
            throw new ApiError(`Unable to create account. Status: ${response.status}`, errorResponse);
        }
        return response;
    },
    deleteAccount: async (jwt: string) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/account`, {
            method: 'delete',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt
            }
        });
        return response;
    },
    finalizeBooking: async (
        clinicId: number,
        bookingId: string,
        jwt: string | null,
        bookingData: FinalizeBookingRequest
    ) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/bookings/${bookingId}`, {
            method: 'put',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                ...(jwt !== null && {'x-user-token': jwt})
            },
            body: JSON.stringify(bookingData)
        });

        if (!response.ok) {
            const errorResponse = (await response.json()) as ApiErrorResponse;
            //console.error(await response.text())
            throw new ApiError(`Unable to reserve time slot for clinic. Status: ${response.status}`, errorResponse);
        }

        const responseData = (await response.json()) as FinalizeBookingResponse;
        return responseData;
    },

    submitHealthDeclaration: async (clinicId, jwt, data) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/healthDecl`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                ...(jwt !== null && {'x-user-token': jwt})
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorResponse = (await response.json()) as ApiErrorResponse;
            //console.error(await response.text())
            throw new ApiError(`Unable to submit health declaration. Status: ${response.status}`, errorResponse);
        }

        return;
    },

    validatePnr: async (pnr: string) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/user/validate`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                identity: pnr
            })
        });

        if (!response.ok) {
            if (response.status === 400) {
                throw new InvalidIdentityError('Not a valid personal number');
            }

            console.error(await response.text());
            throw new Error(`Unable to validate identity. Status: ${response.status}`);
        }

        const responseData = (await response.json()) as PnrJSON;
        return responseData;
    },

    getSingleBooking: async (bookingCode, identity) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v2/user/bookings/single`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: bookingCode,
                identity: identity
            })
        });

        if (!response.ok) {
            if (response.status === 404) {
                throw new NotFoundError('No such resource (booking/clinic/etc...) can be found.');
            }
            if (response.status === 403) {
                throw new ForbiddenError('User token is required to be sent to allow this');
            }
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }

            console.error(await response.text());
            throw new Error(`Booking not found. Status: ${response.status}`);
        }

        const responseData = (await response.json()) as BookingResponse;
        return responseData;
    },

    getBookings: async (jwt) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/user/bookings`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            console.error(await response.text());
            throw new Error(`Bookings not found. Status: ${response.status}`);
        }
        const responseData = (await response.json()) as Array<BookingResponse>;
        return responseData;
    },

    patchConsentRequests: async (jwt, consentRequestAnswer) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/user/consents/${consentRequestAnswer.id}`, {
            method: 'PATCH',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                'x-user-token': jwt
            },
            body: JSON.stringify({
                status: consentRequestAnswer.answer
            })
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            console.error(await response.text());
            throw new Error(`Could not update Booking: ${response.status}`);
        }
    },

    patchBooking: async (bookingId, clinicId, bookingData, jwt) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/clinics/${clinicId}/bookings/${bookingId}`, {
            method: 'PATCH',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                ...(jwt !== null && {'x-user-token': jwt})
            },
            body: JSON.stringify(bookingData)
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            console.error(await response.text());
            throw new Error(`Could not update Booking: ${response.status}`);
        }
    },

    getHistory: async (jwt, userId) => {
        const {baseUrl, apiKey} = config;

        const response = await fetchMaintMode(`${baseUrl}/v1/user/${userId || 'me'}/vaccinationHistory`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            throw new Error(`User history not found. Status: ${response.status}`);
        }
        const responseData = (await response.json()) as Array<HistoryResponse>;
        return responseData;
    },

    getVaccineCategories: async () => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/vaccineCategories`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey
            }
        });
        if (!response.ok) {
            throw new Error(`Unable to fetch vaccine categories. Status: ${response.status}`);
        }
        const responseData = (await response.json()) as Array<VaccineCategoryResponse>;
        return responseData;
    },

    addManualVaccination: async (jwt, userId, payload) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/${userId}/vaccinationHistory`, {
            method: 'post',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            throw new Error(`Failed to add historic entry. Status: ${response.status}`);
        }
        return (await response.json()) as HistoryResponse;
    },

    editManualVaccination: async (jwt, userId, vaccinationId, payload) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/${userId}/vaccinationHistory/${vaccinationId}`, {
            method: 'put',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            throw new Error(`Failed to edit historic entry. Status: ${response.status}`);
        }
        return (await response.json()) as HistoryResponse;
    },

    deleteManuallyAddedVaccination: async (jwt, vaccinationId, userId) => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/user/${userId}/vaccinationHistory/${vaccinationId}`, {
            method: 'delete',
            headers: {
                'x-api-key': apiKey,
                'x-user-token': jwt,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError('User token has expired');
            }
            throw new Error(`Failed to delete historic entry. Status: ${response.status}`);
        }
    },

    getInfoTexts: async () => {
        const {baseUrl, apiKey} = config;
        const response = await fetchMaintMode(`${baseUrl}/v1/infoTexts`, {
            method: 'get',
            headers: {
                'x-api-key': apiKey
            }
        });
        if (!response.ok) {
            throw new Error(`Unable to fetch info texts. Status: ${response.status}`);
        }
        const responseData = (await response.json()) as InfoTextsResponse;
        return responseData;
    }
};
